import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/create-task',
    name: 'createTask',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/create-task.vue'),
    meta: {
      title: '创建任务'
    }
  },
  {
    path: '/task-details',
    name: 'taskDetails',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/task-details.vue'),
    meta: {
      title: '任务详情'
    }
  },
  {
    path: '/task-index',
    name: 'taskIndex',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/task-index.vue'),
    meta: {
      title: '任务列表'
    }
  }
]

export default routes