<template>
  <div class="page-home">
    <!-- 遍历父级分组 -->
    <div v-for="(group, groupIndex) in routerList" :key="groupIndex" class="group">
      <!-- 父级标题 -->
      <div class="group-title">{{ group.name }}</div>
      <!-- 子级展示 -->
      <van-cell-group>
        <van-cell
            v-for="(child, childIndex) in group.children"
            :key="childIndex"
            :title="child.name"
            clickable
            @click="goPage(child)"
        >
          <template #value>
            <van-icon name="arrow" class="icon-arrow" />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script lang="ts" setup>
import router from '@/router';

// 定义类型
type ParentList = {
  name: string;
  children: Array<{
    name: string;
    path: string;
  }>;
};

// 父级和子级结合的类型
type List = ParentList;

// 数据列表
const routerList: List[] = [
  {
    name: '工单管理',
    children: [
      { name: '创建工单', path: 'createOrder' },
      { name: '预览工单', path: 'previewOrder' },
      { name: '工单详情', path: 'orderDetails' },
    ],
  },
  {
    name: '作业管理',
    children: [
      { name: '填写作业结果', path: 'worker' },
      { name: '填写质检结果', path: 'inspector' },
      { name: '填写并检查作业结果', path: 'leader' },
    ],
  },
  {
    name: '审批管理',
    children: [
      { name: '新建审批', path: 'selectAudit' },
      { name: '审批详情', path: 'auditDetails' },
      { name: '新增审批', path: 'createAudit' },
    ],
  },
  {
    name: '任务管理',
    children: [
      { name: '创建任务', path: 'createTask' },
      { name: '任务详情', path: 'taskDetails' },
    ],
  },
];

// 页面跳转方法
const goPage = (item: { name: string; path: string }) => {
  if (item.path) {
    router.push({ name: item.path });
  }
};
</script>

<style lang="scss">
.page-home {
  padding: 16px;

  .group {
    margin-bottom: 24px;

    .group-title {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-bottom: 8px;
    }

    .van-cell-group {
      background: #fff;
      border-radius: 8px;

      .van-cell {
        font-size: 14px;
        text-align: left;
        border-bottom: 1px solid #dde2e5;

        &:last-child {
          border-bottom: none;
        }
      }

      .icon-arrow {
        font-size: 14px;
        color: #888;
      }
    }
  }
}
</style>