import './utils/rem'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import './utils/interaction'
import FontSizeManager from "./utils/fontSizeManager";
import {
  Button,
  Form,
  Field,
  Icon,
  DatePicker,
  Popup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Uploader,
  Picker,
  PickerGroup,
  Calendar,
  Area,
  TimePicker,
  RadioGroup,
  Radio,
  Cell,
  CellGroup,
  Toast,
  Tab,
  Tabs,
  Divider,
  Image,
  ImagePreview,
  Tag,
  Pagination,
  Grid,
  GridItem,
  Badge,
  Swipe,
  SwipeItem,
  Search,
  DropdownMenu,
  DropdownItem,
  Card, Empty, Loading
} from 'vant'

const app = createApp(App)

const appUseList = [
  store,
  router,
  Button,
  Form,
  Field,
  Icon,
  DatePicker,
  Popup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Uploader,
  Picker,
  PickerGroup,
  Calendar,
  Area,
  TimePicker,
  RadioGroup,
  Radio,
  Cell,
  CellGroup,
  Toast,
  Tab,
  Tabs,
  Divider,
  Tag,
  Pagination,
  Grid,
  GridItem,
  Badge,
  Swipe,
  SwipeItem,
  Search,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  Empty,
  Loading,
  Image,
  ImagePreview
]

appUseList.forEach(component => {
  app.use(component)
})

// 初始化动态字体大小管理
const fontSizeManager = new FontSizeManager();
fontSizeManager.initializeFontSize();
// 添加到全局属性中（供组件使用）
app.config.globalProperties.$fontSizeManager = fontSizeManager;
app.mount('#app')
