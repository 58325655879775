// src/utils/fontSizeManager.ts
export default class FontSizeManager {
    private defaultFontSize: number;
    private sizes: Record<string, number>;
    private localStorageKey: string;

    constructor() {
        this.defaultFontSize = 16; // 默认根字体大小，稍后动态计算
        this.sizes = {
            small: 0.8, // 小字体倍率
            medium: 1, // 中字体倍率
            large: 1.5, // 大字体倍率
        };
        this.localStorageKey = "rootFontSize";
    }

    // 动态计算设备的默认字体大小
    private calculateDefaultFontSize(): number {
        const testDiv = document.createElement("div");
        testDiv.style.width = "1rem";
        testDiv.style.visibility = "hidden";
        testDiv.style.position = "absolute";
        document.body.appendChild(testDiv);
        const fontSize = parseFloat(window.getComputedStyle(testDiv).width);
        document.body.removeChild(testDiv);
        return fontSize || 16; // 如果无法获取，返回 16px
    }

    // 初始化根字体大小（在应用启动时调用一次）
    public initializeFontSize() {
        this.defaultFontSize = this.calculateDefaultFontSize();

        // 从 localStorage 获取保存的字体大小设置
        const savedSize = localStorage.getItem(this.localStorageKey) || "medium";

        // 应用字体大小
        this.setFontSize(savedSize);
    }

    // 设置字体大小并保存到 localStorage
    public setFontSize(size: string) {
        if (this.sizes[size] !== undefined) {
            console.log(this.defaultFontSize)
            const newFontSize = this.defaultFontSize * this.sizes[size];
            document.documentElement.style.fontSize = `${newFontSize}px`; // 修改根字体大小
            localStorage.setItem(this.localStorageKey, size); // 保存设置
        }
    }

    // 获取当前字体大小
    public getCurrentFontSize(): string {
        return localStorage.getItem(this.localStorageKey) || "medium";
    }
}