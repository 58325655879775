import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/create-order',
    name: 'createOrder',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/create-order.vue'),
    meta: {
      title: '创建工单'
    }
  },
  {
    path: '/preview-order',
    name: 'previewOrder',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/preview-order.vue'),
    meta: {
      title: '预览工单'
    }
  },
  {
    path: '/order-details',
    name: 'orderDetails',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/order-details.vue'),
    meta: {
      title: '工单详情'
    }
  },
  {
    path: '/order-index',
    name: 'orderIndex',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/order-index.vue'),
    meta: {
      title: '工单列表'
    }
  },
  {
    path: '/worker',
    name: 'worker',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/worker.vue'),
    meta: {
      title: '填写作业结果'
    }
  },
  {
    path: '/leader',
    name: 'leader',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/leader.vue'),
    meta: {
      title: '填写作业结果'
    }
  }
]

export default routes